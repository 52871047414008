.rodape__conteudo{
	padding: 35px 0px 30px 0px;
	background-color: #0a1f3c;
	color: #fff;
}
.titulo__rodape{
	font-family: 'montserratbold';
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 5px;
}
.rodape__menu{
	font-family: 'montserratsemibold';
	font-size: 14px;
	line-height: 18px;

	a{
		display: block;
		padding: 2px 0px;
	}
}
.contato__box{
	display: flex;
	align-items: flex-start;
	margin-bottom: 5px;
	font-family: 'montserratmedium';

	.contato__icone{
		margin-right: 5px;
		width: 18px;
	}
}
.contato__sociais{
	padding-top: 10px;
	display: flex;

	a{
		display: block;
		margin: 0px 5px;
		font-size: 22px;
		line-height: 22px;
	}
}
.rodape__mapa{
	.lazy-embed{
		--width: 300;
		--height: 260;
		margin-right: auto;
		margin-left: auto;
	}
}
.rodape__logo{
	height: 100%;
	display: flex;
	align-items: center;
}
.rodape__creditos{
	padding: 18px 0px 12px 0px;
	background-color: #031732;
	color: #fff;
	text-align: center;
	font-size: 10px;
	line-height: 14px;
}
.gv8__box{
	display: flex;
	justify-content: center;
	align-items: center;

	.gv8__logo{
		margin-left: 5px;
	}
}
@include media-breakpoint-down (md) {
	.rodape__conteudo{
		text-align: center;
	}
	.rodape__logo{
		display: flex;
		justify-content: center;
		margin-bottom: 20px;
	}
	.rodape__menu{
		margin-bottom: 30px;
	}
	.contato__box{
		justify-content: center;
		flex-direction: column;
		align-items: center;
		text-align: center;
	}
	.contato__icone{
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 0px;
		margin-bottom: 2px;
	}
	.titulo__rodape{
		margin-bottom: 10px;
	}
	.contato__sociais{
		justify-content: center;
		margin-bottom: 40px;
	}
}
