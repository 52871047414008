.contato__mapa{
	.lazy-embed{
		--width: 600;
		--height: 524;
	}
}
.contato__interna{
	padding: 60px 0px 50px 0px;
}
.contato__conteudo{
	margin-bottom: 20px;
}
.contato__form__titulo{
	margin-bottom: 15px;
}
.contato__input{
	display: block;
	width: 100%;
	border: none;
	background-color: #f7f7f7;
	font-size: 15px;
	line-height: 19px;
	color: #1f1f1f;
	resize: none;
	padding: 10px 20px;

	&:focus{
		outline: none;
	}

	&::placeholder{
		color: #1f1f1f;
	}
}
.contato__btn{
	display: flex;
	justify-content: center;
	padding-top: 5px;
	margin-bottom: 20px;

	.btn__conteudo{
		border: none;

		&:focus{
			outline: none;
		}
	}
}
@include media-breakpoint-down (md) {
	.contato__mapa{
		.lazy-embed{
			margin-right: auto;
			margin-left: auto;
		}
	}
}
