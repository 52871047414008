.cursos__interna__header{
	margin-bottom: 25px;
}
.cursos__interna{
	padding: 70px 0px 90px 0px;
	overflow: hidden;
}
.cursos__interna__card{
	max-width: 409px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	display: block;

	&:hover{
		text-decoration: none;

		.curso__interna__img{
			&:after{
				opacity: 1;
			}
		}
	}
}
.curso__interna__img{
	position: relative;

	&:after{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		content: '';
		background-color: rgba(#0a1f3c, 0.7);
		transition: all 0.3s linear;
		opacity: 0;
	}
}
.curso__interna__titulo{
	font-family: 'trajan';
	font-size: 24px;
	line-height: 28px;
	color: #10255f;
	padding: 18px 15px 14px 15px;
	background-color: #dfe2e6;
	text-align: center;
}
.cursos__interna__owl.owl-carousel{
	padding: 0px 25px;
	position: relative;

	&:before{
		content: '';
		width: 109px;
		height: 371px;
		top: 50%;
		transform: translateY(-50%);
		left: -50px;
		position: absolute;
		background-image: url('../images/louro__curso__left.png');
		background-repeat: no-repeat;
		background-size: 109px 371px;
		background-position: center;
		z-index: -1;
	}
	&:after{
		content: '';
		position: absolute;
		width: 109px;
		height: 371px;
		top: 50%;
		transform: translateY(-50%);
		right: -40px;
		z-index: -1;
		background-image: url('../images/louro__curso__right.png');
		background-repeat: no-repeat;
		background-size: 109px 371px;
		background-position: center;
	}

	.owl-dots{
		display: none;
	}

	.owl-nav{
		button.owl-prev,
		button.owl-next{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			display: flex;
			align-items: center;
			justify-content: center;
			width: 52px;
			height: 52px;
			background-color: #dfe2e6;
			font-size: 34px;
			line-height: 34px;
			transition: all 0.3s linear;
			box-shadow: 0px 0px 10px #10255f;

			&:hover{
				background-color: #10255f;
				color: #dfe2e6;
				box-shadow: 0px 0px 10px #dfe2e6;
			}

			&:focus{
				outline: none;
			}
		}
		button.owl-prev{
			left: 0px;
		}
		button.owl-next{
			right: 0px;
		}
	}
}
.cursos__carousel{
	max-width: 648px;
	width: 100%;
	margin-right: 0px;
	margin-left: auto;
	position: relative;
	padding: 0px 20px;

	.cursos__prev,
	.cursos__next{
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50px;
		height: 50px;
		background-color: #dfe2e6;
		color: #0a1f3c;
		font-size: 34px;
		line-height: 34px;
		top: 50%;
		transform: translateY(-50%);
		transition: all 0.3s linear;
		box-shadow: 0px 0px 5px rgba(#10255f, 0.6);

		&:hover{
			background-color: #10255f;
			color: #dfe2e6;
			box-shadow: 0px 0px 10px #dfe2e6;
			text-decoration: none;
		}

	}
	.cursos__prev{
		left: 0px;
	}
	.cursos__next{
		right: 0px;
	}
	&:before{
		width: 905px;
		height: 612px;
		bottom: 0px;
		left: 50%;
		transform: translateX(-50%);
		content: '';
		position: absolute;
		background-image: url('../images/louros__formacao.png');
		background-repeat: no-repeat;
		background-size: 905px 612px;
		background-position: center bottom;
	}
}
.row__formacao{
	margin-bottom: 20px;
}
.segura__btn__voltar{
	padding-top: 40px;
	display: flex;
	justify-content: center;
}
.formacao__cg{
	margin-bottom: 40px;
}
.aprimoramento__acesse{
	display: flex;
	font-family: 'montserratsemibold';
	font-size: 13px;
	line-height: 17px;
	color: #0a1f3c;
	margin-top: 30px;
	padding: 14px 15px;
	background-color: #f7f7f7;

	span{
		margin-right: 5px;
	}
}
@include media-breakpoint-only (lg) {
	.cursos__carousel{
		&:before{
			width: 600px;
			background-size: 600px auto;
		}
	}
}
@include media-breakpoint-down (md) {
	.cursos__carousel{
		&:before{
			width: 760px;
			background-size: 760px auto;
		}
	}
}
