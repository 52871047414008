.contato__afilie{
	padding: 70px 0px 90px 0px;
}
.afilie__input{
	display: block;
	width: 100%;
	height: auto;
	padding: 12px 16px;
	background-color: #ededed;
	font-size: 15px;
	line-height: 19px;
	color: #1f1f1f;
	border: none;

	&::placeholder{
		color: #1f1f1f;
	}

	&:focus{
		outline: none;
	}
}
.segura__sexo{
	display: flex;
	align-items: center;
	font-size: 15px;
	flex-wrap: wrap;
	line-height: 19px;
	color: #fff;

	span{
		margin-right: 10px;
	}
}
.sexo__label{
	margin-bottom: 0px;
	margin: 5px;
	padding-left: 26px;
	position: relative;

	&:before{
		position: absolute;
		content: '';
		width: 20px;
		height: 20px;
		border-radius: 50%;
		border: 2px solid #fff;
		left: 0px;
		top: 45%;
		transform: translateY(-50%);
	}

	&:after{
		content: '';
		position: absolute;
		top: 45%;
		transform: translateY(-50%);
		width: 8px;
		height: 8px;
		background-color: #fff;
		border-radius: 50%;
		left: 6px;
		opacity: 0;
		transition: all 0.3s linear;
	}
}
.sexo__input{
	position: fixed;
	left: -200vw;
	&:checked{
		~ .sexo__label{
			&:after{
				opacity: 1;
			}
		}
	}
}
.afilie__form{
	padding: 30px 50px 40px 50px;
	background-color: #0a1f3c;
}
.afilie__titulo{
	font-family: 'trajan';
	font-size: 24px;
	line-height: 28px;
	color: #fff;
	margin-bottom: 15px;
}
.afilie__cg{
	margin-bottom: 26px;
}
.afilie__modal{
	overflow: hidden;

	.modal-dialog{
		max-width: 1110px;
		width: 90%;
		padding: 0px;
		overflow: hidden;

		.modal-content{
			border: none;
			border-radius: 0px;
			padding: 0px;
		}
	}
	.row{
		padding: 0px;
		max-width: 1110px;
		margin-right: auto;
		margin-left: auto;
		justify-content: space-between;

		.col-lg-6{
			padding: 0px;
		}
		.col-lg-6{
			padding: 0px;
		}
	}
	.modal-body{
		padding: 0px;
	}
	.modal-header{
		padding: 0px;
		border: none;
	}
	.sub__modal__evento{
		font-size: 58px;
		line-height: 62px;
		text-align: left;
	}
	.titulo__modal__evento{
		font-size: 38px;
		line-height: 42px;
		text-align: left;
		margin-bottom: 20px;
	}
}
.pagseguro__modal{
	padding: 20px 20px;
}
.segura__afilie__btn{
	display: flex;
	justify-content: center;
	padding-top: 20px;
}
.afilie__btn{
	display: flex;
	width: 180px;
	height: 42px;
	justify-content: center;
	align-items: center;
	background-color: #fff;
	color: #0a1f3c;
	font-family: 'montserratsemibold';
	font-size: 12px;
	line-height: 12px;
	border: none;

	&:focus{
		outline: none;
	}
}
@include media-breakpoint-only (lg) {
	.afilie__form{
		padding: 24px 15px 40px 15px;
	}
}
@include media-breakpoint-down (md) {
	.afilie__form{
		padding: 24px 15px 40px 15px;
		.col-xl-5{
			order: 3;
			&:first-child{
				order: 1;
			}
		}
		.col-xl-2{
			order: 2;
		}
	}
	.modal__eventos,
	.afilie__modal{
		.modal__img{
			display: none;
		}
	}

}
@include media-breakpoint-down (sm) {
	.afilie__modal,
	.modal__eventos{
		.sub__modal__evento{
			font-size: 34px;
			line-height: 38px;
		}
		.titulo__modal__evento{
			font-size: 28px;
			line-height: 32px;
		}
	}
}
