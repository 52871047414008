.loja__interna{
	padding: 90px 0px 85px 0px;
}
.loja__interna__header{
	margin-bottom: 40px;
}
.loja__card{
	display: block;
	max-width: 296px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;

	&:hover{
		text-decoration: none;

		.loja__img{
			&:before{
				opacity: 1;
			}
		}
	}
}
.loja__conteudo{
	padding: 16px 20px 28px 20px;
	background-color: #dfe2e6;
}
.loja__item__titulo{
	font-family: 'trajan';
	font-size: 18px;
	line-height: 22px;
	color: #10255f;
	text-align: center;
	margin-bottom: 4px;
}
.loja__item__sub{
	font-family: 'montserratbold';
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: rgba(#0a1f3c,0.75);
	margin-bottom: 5px;
}
.loja__item__valor{
	font-family: 'montserratbold';
	color: #0a1f3c;
	text-align: center;
	margin-bottom: 10px;
	font-size: 18px;
	line-height: 22px;
}
.loja__item__btn{
	text-align: center;
}
.loja__img{
	position: relative;

	&:before{
		z-index: 2;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		content: '';
		position: absolute;
		background-color: rgba(#0a1f3c, 0.5);
		transition: all 0.3s linear;
		opacity: 0;
	}
}
.lojas__owl.owl-carousel{
	padding: 0px 15px;
	position: relative;

	&:before{
		content: '';
		width: 109px;
		height: 371px;
		top: 50%;
		transform: translateY(-50%);
		left: -50px;
		position: absolute;
		background-image: url('../images/louro__curso__left.png');
		background-repeat: no-repeat;
		background-size: 109px 371px;
		background-position: center;
		z-index: -1;
	}
	&:after{
		content: '';
		position: absolute;
		width: 109px;
		height: 371px;
		top: 50%;
		transform: translateY(-50%);
		right: -40px;
		z-index: -1;
		background-image: url('../images/louro__curso__right.png');
		background-repeat: no-repeat;
		background-size: 109px 371px;
		background-position: center;
	}

	.owl-dots{
		display: none;
	}

	.owl-nav{
		button.owl-prev,
		button.owl-next{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			display: flex;
			align-items: center;
			justify-content: center;
			width: 52px;
			height: 52px;
			background-color: #dfe2e6;
			font-size: 34px;
			line-height: 34px;
			transition: all 0.3s linear;
			box-shadow: 0px 0px 10px #10255f;

			&:hover{
				background-color: #10255f;
				color: #dfe2e6;
				box-shadow: 0px 0px 10px #dfe2e6;
			}

			&:focus{
				outline: none;
			}
		}
		button.owl-prev{
			left: 0px;
		}
		button.owl-next{
			right: 0px;
		}
	}
}
.grupo__listagem{
	padding-top: 20px;

	.aprimoramento__acesse{
		margin-top: 0px;
		margin-bottom: 10px;
	}
}
