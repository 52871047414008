.pesquisa__form{
	border-right: 1px solid #c6c6c6;
	border-left: 1px solid #c6c6c6;
	width: 100%;
	display: flex;
}
.pesquisa__input{
	width: 100%;
	display: block;
	font-family: 'montserratmedium';
	font-size: 14px;
	line-height: 18px;
	padding: 8px 12px;
	border: none;
	color: #6a6a6a;

	&::placeholder{
		color: #6a6a6a;
	}
}
.btn__pesquisa{
	font-size: 20px;
	line-height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0px 10px;
	border: none;
	background-color: #fff;
	color: #6a6a6a;
}
.main__sociais{
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 20px;
}
.main__menu{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
}
.menu__item--active{
	.menu__link{
		background-color: #20263e;
		color: #fff;
	}
}
@include media-breakpoint-up (xl) {
	.topo__main{
		box-shadow: 0px 0px 15px rgba(#000, 0.3);

		.container{
			display: flex;
		}
	}
	.topo{
		.container{
			max-width: 1300px;
			width: 100%;
			padding: 0px 15px;
		}
	}
	.main__pesquisa{
		width: 380px;
	}
	.main__dados{
		display: flex;
		justify-content: flex-end;
		width: 100%;
		padding: 8px 0px;
		border-bottom: 1px solid #c6c6c6;
		align-items: center;

		a{
			display: block;
			font-size: 23px;
			line-height: 23px;
			color: #0a1f3c;
			margin: 0px 5px;
		}
	}
	.main__conteudo{
		flex-grow: 1;
	}
	.main__nav{
		padding: 16px 0px 20px 0px;
	}
	.main__menu{
		display: flex;
		justify-content: space-between;
		padding: 0px 10px;
	}
	.menu__link{
		border: none;
		display: block;
		background-color: transparent;
		font-family: 'montserratsemibold';
		font-size: 13px;
		line-height: 13px;
		color: #272727;
		padding: 10px 8px 9px 8px;
		transition: all 0.3s linear;

		&[aria-expanded="true"] {
			background-color: #20263e;
			color: #fff;
		}

		&:hover{
			background-color: #20263e;
			color: #fff;
			text-decoration: none;
		}

		&:focus{
			outline: none;
		}
	}
	.sub__link{
		width: 160px;
		display: block;
		border-bottom: 1px solid rgba(#ffffff, 0.67);
		padding: 12px 0px;
		font-family: 'montserratsemibold';
		font-size: 13px;
		line-height: 17px;
	}
	.menu__sub{
		padding: 0px 10px;
		border: none;
		background-color: #20263e;
		color: #fff;
		transform: none !important;
		top: calc(100% + 10px) !important;
		left: calc(50% - 90px) !important;
	}
	.main__logo{
		padding-top: 13px;
		display: block;
		margin-bottom: -40px;
		padding-right: 5px;
	}
	.topo__mbl,
	.mbl__pesquisa{
		display: none;
	}
}

@media (max-width: 1250px) {
	.main__logo{
		padding-top: 13px;
		display: block;
		margin-bottom: -40px;
		max-width: 260px;
	}
}

@include media-breakpoint-down (lg) {
	.topo__main{
		position: fixed;
		top: 0px;
		left: -250px;
		z-index: 1000;
		width: 250px;
		height: 100%;
		overflow-y: auto;
		transition: all 0.3s linear;
		background-color: #fff;

		.container{
			padding: 0px;
		}

		&.topo__main--shown{
			left: 0px;
		}
	}
	.bg__menu{
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 999;
		background-color: rgba(#000, 0.6);
		animation: fadeIn 0.4s linear;

		&.hide{
			animation: fadeOut 0.4s linear;
		}
	}
	.main__logo{
		margin-bottom: 10px;
		padding: 10px;
	}
	.main__pesquisa{
		display: none;
	}
	.main__conteudo{
		display: flex;
		flex-direction: column;
	}
	.main__dados{
		order: 2;
	}
	.main__nav{
		order: 1;
	}
	.menu__link{
		border: none;
		display: block;
		background-color: transparent;
		font-family: 'montserratsemibold';
		font-size: 13px;
		line-height: 13px;
		color: #272727;
		padding: 10px 8px 9px 8px;
		transition: all 0.3s linear;
		width: 100%;
		text-align: left;

		&[aria-expanded="true"] {
			background-color: #20263e;
			color: #fff;
		}

		&:hover{
			background-color: #20263e;
			color: #fff;
			text-decoration: none;
		}

		&:focus{
			outline: none;
		}
	}
	.menu__item{
		margin-bottom: 5px;
	}
	button{
		&.menu__link{
			position: relative;

			&:after{
				content: '';
				width: 18px;
				height: 10px;
				background-color: currentColor;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 10px;
				clip-path: polygon(0 0, 50% 100%, 100% 0);
			}
		}
	}
	// .menu__sub{
	// 	position: relative !important;
	// 	left: 0px !important;
	// 	top: -5px !important;
	// 	transform: translate3d(0px, 0px, 0px) !important;
	// 	padding: 0px;
	// 	border-radius: 0px;
	// 	height: 100%;
	// 	margin: 0px;
	// 	border: none;
	// 	border-top: 1px solid rgba(#ffffff, 0.67);
	// }
	.sub__link{
		width: 100%;
		display: block;
		border-bottom: 1px solid rgba(#ffffff, 0.67);
		padding: 5px 10px;
		font-family: 'montserratsemibold';
		font-size: 13px;
		line-height: 17px;
		background-color: rgba(#20263e, 0.8);
		color: #fff;

		&:hover{
			text-decoration: none;
			color: #fff;
		}
	}
	.dropdown{
		display: flex;
		flex-direction: column;
	}
	.menu__sub{
		position: relative !important;
		transform: none !important;
		padding: 0px;
		border-radius: 0px;
		margin: 0px;
		border: none;
		border-top: 1px solid rgba(#ffffff, 0.67);
	}
	.main__sociais{
		a{
			display: block;
			font-size: 23px;
			line-height: 23px;
			color: #0a1f3c;
			margin: 0px 5px;
		}
	}
	.mbl__pesquisa{
		position: fixed;
		z-index: 1000;
		left: 50%;
		transform: translateX(-50%);
		top: -200px;
		max-width: 800px;
		width: 95%;
		transition: all 0.3s linear;

		&.mbl__pesquisa--shown{
			top: 50px;
		}
	}
	.topo__mbl{
		padding: 10px 0px;
		box-shadow: 0px 0px 15px rgba(#000, 0.3);

		.container{
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
	.mbl__btn{
		font-size: 24px;
		line-height: 24px;
		border: none;
		background-color: transparent;
		padding: 0px;

		&:focus{
			outline: none;
		}
	}
	.mbl__logo{
		display: block;
		max-width: 250px;
	}
}
@include media-breakpoint-down (sm) {
	.mbl__logo{
		max-width: 220px;
		padding: 0px 10px;
	}
}
