.credenciados__interna{
	padding: 70px 0px 40px 0px;
	overflow: hidden;
}
.credenciado__interna__desc{
	margin-bottom: 50px;
	text-align: justify;
	font-size: 13px;
	line-height: 17px;
	letter-spacing: 0.6px;
}
.credenciado__card__interna{
	margin-bottom: 45px;
}
.credenciado__card__img{
	text-align: center;
	position: relative;

	&:before{
		content: '';
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 0px;
		background-image: url('../images/louros__credenciados.png');
		background-repeat: no-repeat;
		background-position: center bottom;
		background-size: 591px 395px;
		width: 591px;
		height: 395px;
	}
}
.credenciado__card__titulo{
	font-family: 'trajan';
	font-size: 24px;
	line-height: 28px;
	margin-bottom: 20px;
	color: #10255f;
}
.credenciado__card__desc{
	text-align: justify;
}
.credenciado__card__btn{
	padding-top: 20px;
	display: flex;
	justify-content: flex-end;
}
.paginacao__custom{
	display: flex;
	justify-content: flex-end;
	font-family: 'montserratmedium';
	font-size: 18px;
	line-height: 18px;
	align-items: center;

	.paginacao__item{
		display: block;
		padding: 0px 10px;
		color: #0a1f3c;
		position: relative;

		&:hover{
			text-decoration: none;
			font-family: 'montserratbold';
		}

		&.paginacao__item--active{
			font-family: 'montserratbold';
		}

		&:last-child{
			&:after{
				display: none;
			}
		}

		&:after{
			content: '';
			height: 14px;
			background-color: #0a1f3c;
			width: 2px;
			right: 0px;
			top: 50%;
			transform: translateY(-60%);
			position: absolute;
		}
	}
}
.escola__footer{
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	padding-top: 30px;
}

.escola__contato__titulo{
	font-family: 'montserratsemibold';
	font-size: 14px;
	line-height: 18px;
	color: #0a1f3c;
	margin-bottom: 5px;
}
.escola__contato__item{
	font-family: 'montserratsemibold';
	font-size: 14px;
	line-height: 18px;
	color: #0a1f3c;
	margin-bottom: 5px;
}
.row__formacao{
	justify-content: space-between;
}
.credencial__acesse{
	margin-bottom: 20px;
}
.curriculo__btn{
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	padding: 8px 15px;
	color: #fff;
	text-align: left;
	font-family: 'montserratsemibold';
	font-size: 13px;
	line-height: 17px;
	background-color: #0a1f3c;
	border: none;

	&:focus{
		outline: none;
	}

	&:after{
		content: '\f078';
		font-family: 'Font Awesome 5 Free';
		font-size: 30px;
		line-height: 30px;
		font-weight: 900;
		display: inline-block;
	}
}
.curriculo__titulo{
	margin-top: 15px;
	font-family: 'trajan';
	font-size: 30px;
	line-height: 34px;
	color: #0a1f3c;
	margin-bottom: 10px;
}
@include media-breakpoint-only (lg) {
	.credenciado__card__img{
		&:before{
			width: 480px;
			background-size: 480px auto;
		}
	}
}
@include media-breakpoint-down (md) {
	.credenciado__card__img{
		margin-bottom: 30px;
	}
	.segura__curriculo{
		margin-bottom: 40px;
	}
}
@include media-breakpoint-down (sm) {
	.escola__footer{
		flex-direction: column;
		align-items: center;
		text-align: center;
	}
}
@include media-breakpoint-down (xs) {
	.credenciado__card__img{
		&:before{
			display: none;
		}
	}
}
