.banner__carousel{
	max-width: 1920px;
	width: 100%;
	overflow: hidden;
	margin-right: auto;
	margin-left: auto;
	position: relative;
}
.banner__inner{
	max-width: 1920px;
	width: 160%;
	left: 50%;
	transform: translateX(-50%);
	position: relative;
}
.banner__prev,
.banner__next{
	font-size: 40px;
	line-height: 40px;
	color: #fff;
	text-shadow: 0px 0px 5px rgba(#000, 0.8);
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	transition: all 0.3s linear;

	&:hover{
		text-decoration: none;
		color: darken(#fff, 20%);
	}
}
.banner__prev{
	left: 2%;
}
.banner__next{
	right: 2%;
}
.introducao{
	padding: 56px 0px 50px 0px;
	overflow: hidden;

	.row{
		.col-lg-6{
			&:last-child{
				position: relative;

				&:after{
					width: 1077px;
					height: 924px;
					content: '';
					position: absolute;
					left: 50%;
					transform: translateX(-50%);
					bottom: -90px;
					background-image: url('../images/louros__home.png');
					background-repeat: no-repeat;
					background-position: center bottom;
					background-size: 1077px 882px;
				}
			}
		}
	}
}
.titulo__secao{
	font-family: 'trajan';
	font-size: 24px;
	line-height: 28px;
	color: #10255f;
	font-weight: 400;
	margin-bottom: 20px;
}
.frase__conteudo{
	font-family: 'montserratsemibold';
	font-style: italic;
	font-size: 17px;
	line-height: 21px;
	color: #174584;
	margin-bottom: 5px;
}
.frase__autor{
	font-family: 'montserratsemibold';
	font-size: 14px;
	line-height: 18px;
	text-align: right;
	color: #174584;
}
.introducao__frase{
	margin-bottom: 30px;
}
.introducao__desc{
	text-align: justify;
	margin-bottom: 30px;
}
.introducao__listagem{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 35px;
}
.introducao__item{
	position: relative;
	display: flex;
	align-items: center;
	font-family: 'trajan';
	font-size: 18px;
	line-height: 22px;
	color: #0a1f3c;
	margin-bottom: 5px;

	&:before{
		content: '';
		display: inline-block;
		width: 22px;
		height: 22px;
		margin-right: 5px;
		background-image: url('../images/check.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		margin-bottom: 5px;
	}
}
.btn__conteudo{
	width: 186px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #0a1f3c;
	color: #fff;
	font-family: 'montserratsemibold';
	font-size: 12px;
	line-height: 16px;
	transition: all 0.3s linear;

	&:hover{
		text-decoration: none;
		color: #fff;
		background-color: #174584;
	}
}
.credencial__card__home{
	max-width: 617px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	position: relative;
	margin-bottom: 10px;
}
.credencial__img{
	position: relative;
	z-index: 2;
}
.credencial__home__txt{
	position: absolute;
	left: 0px;
	top: 0px;
	padding-top: 60px;
	z-index: 3;
	width: 332px;
	height: 100%;

	&:before{
		content: '';
		position: absolute;
		background-image: url('../images/credencial__detalhe.png');
		background-position: left top;
		background-size: 332px 517px;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		top: 0px;
		left: 0px;
		z-index: -1;
	}
}
.credencial__home__titulo{
	--linhas: 2;
	max-width: 300px;
	width: 100%;
	font-family: 'trajan';
	font-size: 30px;
	line-height: 34px;
	color: #fff;
	padding-left: 20px;
	margin-bottom: 5px;
}
.credencial__home__desc{
	--linhas: 9;
	font-family: 'montserratsemibold';
	font-size: 14px;
	line-height: 18px;
	padding-left: 20px;
	text-align: justify;
	color: #fff;
	max-width: 264px;
	width: 100%;
}
.credenciados__link{
	display: flex;
	align-items: center;
	font-family: 'montserratsemibold';
	font-size: 18px;
	line-height: 22px;
	color: #0a1f3c;
	position: relative;
	justify-content: space-between;
	transition: all 0.3s linear;
	max-width: 617px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;

	&:hover{
		text-decoration: none;
		color: #174584;

		&:after{
			color: #174584;
		}
	}

	&:after{
		content: '\f054';
		display: inline-block;
		font-weight: 900;
		font-family: 'Font Awesome 5 Free';
		font-size: 24px;
		line-height: 24px;
		color: #0a1f3c;
		margin-bottom: 3px;
	}
}
.video{
	background-color: #f7f7f7;
}
.titulo__video{
	font-family: 'tajan';
	font-size: 24px;
	line-height: 28px;
	color: #0a1f3c;
	margin-bottom: 5px;
}
.video__header{
	padding: 10px 0px;
}
.video__desc{
	font-family: 'montserratsemibold';
	font-size: 13px;
	line-height: 17px;
	text-align: justify;
	color: #0a1f3c;
}
.video__conteudo{
	.lazy-embed{
		margin-right: auto;
		margin-left: auto;
	}
}
.cursos__opcoes{
	display: flex;
	align-items: center;
}
.cursos__link{
	font-family: 'montserratextrabold';

	&:hover{
		text-decoration: none;
	}

	&.cursos__link--active{
		text-decoration: underline;
	}
}
.cursos{
	padding-top: 24px;
	background-color: #f7f7f7;
	padding-bottom: 40px;
}
.cursos__header{
	text-align: justify;
	margin-bottom: 10px;
}
.curso__card{
	max-width: 289px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	display: block;
	position: relative;

	&:hover{
		text-decoration: none;

		.curso__txt{
			opacity: 1;
		}
	}
}
.curso__img{
	text-align: center;
	border: 2px solid #0a1f3c;
	position: relative;

	img{
		filter: grayscale(100%);
	}

	&:after{
		content: '\f067';
		position: absolute;
		bottom: 10px;
		right: 10px;
		font-family: 'Font Awesome 5 Free';
		font-weight: 900;
		font-size: 24px;
		line-height: 24px;
		color: #fff;
		z-index: 5;
	}
	&:before{
		content: '';
		width: 100%;
		height: 100%;
		left: 0px;
		top: 0px;
		background-color: rgba(#0a1f3c, 0.5);
		position: absolute;
		z-index: 3;
	}
}
.curso__home__titulo{
	font-family: 'montserratsemibold';
	font-size: 16px;
	line-height: 20px;
	color: #fff;
	margin-bottom: 10px;
}
.curso__home__desc{
	font-size: 15px;
	line-height: 19px;
	color: #fff;
	--linhas: 8;
	text-align: justify;
}
.cursos__owl.owl-carousel{
	padding: 0px 30px;

	.owl-dots{
		display: none;
	}
	.owl-nav{
		button.owl-prev,
		button.owl-next{
			font-size: 34px;
			line-height: 34px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			color: #0a1f3c;
			transition: all 0.3s linear;

			&:hover{
				color: lighten(#0a1f3c, 15%);
			}

			&:focus{
				outline: none;
			}
		}
		button.owl-prev{
			left: 0px;
		}
		button.owl-next{
			right: 0px;
		}
	}
}
.loja{
	padding: 20px 0px 18px 0px;

	.container{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
.titulo__loja{
	font-family: 'trajan';
	font-size: 30px;
	line-height: 34px;
	color: #0a1f3c;
}
.btn__loja{
	color: #0a1f3c;
	font-size: 36px;
	line-height: 40px;

	span{
		margin: 0px 5px;
	}
}
.eventos{
	padding: 35px 0px 26px 0px;
	background-image: var(--bg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	background-attachment: fixed;
}
.eventos__titulo{
	font-family: 'trajan';
	font-size: 32px;
	line-height: 36px;
	color: #fff;
	margin-bottom: 10px;
}
.eventos__desc{
	color: #fff;
	font-family: 'montserratsemibold';
	font-size: 13px;
	line-height: 17px;
	margin-bottom: 24px;
}
.btn__eventos{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 184px;
	height: 42px;
	max-width: 100%;
	border: 2px solid #fff;
	color: #fff;
	font-family: 'montserratsemibold';
	font-size: 12px;
	line-height: 12px;
	transition: all 0.3s linear;

	&:hover{
		background-color: #fff;
		color: #0a1f3c;
		text-decoration: none;
	}
}
.parceiros{
	padding: 15px 0px 20px 0px;
}
.parceiros__header{
	margin-bottom: 15px;
}
.parceiros__card{
	display: block;
	width: 100%;
	max-width: 166px;
	margin-right: auto;
	margin-left: auto;
}
.parceiros__owl.owl-carousel{
	padding: 0px 30px;

	.owl-dots{
		display: none;
	}
	.owl-nav{
		button.owl-prev,
		button.owl-next{
			font-size: 34px;
			line-height: 34px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			color: #0a1f3c;
			transition: all 0.3s linear;

			&:hover{
				color: lighten(#0a1f3c, 15%);
			}

			&:focus{
				outline: none;
			}
		}
		button.owl-prev{
			left: 0px;
		}
		button.owl-next{
			right: 0px;
		}
	}
}
@include media-breakpoint-up (xl) {
	.introducao{
		.row{
			.col-lg-6{
				&:first-child{
					padding-right: 120px;
				}
			}
		}
	}
	.conteudo__home,
	.conteudo__interna,
	.rodape{
		.container{
			max-width: 1300px;
			width: 100%;
			padding: 0px 15px;
		}
	}
}
@include media-breakpoint-up (lg) {
	.header__cima{
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		margin-bottom: 20px;
	}

	.titulo__video{
		white-space: nowrap;
	}

	.curso__txt{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		padding: 20px 15px;
		background-color: #0a1f3c;
		opacity: 0;
		transition: all 0.3s linear;
		max-height: 100%;
		z-index: 4;
	}
	.video__conteudo{
		.lazy-embed{
			--width: 1920;
			--height: 800;
		}
	}
}
@include media-breakpoint-down (md) {
	.introducao{
		.introducao__btn{
			margin-bottom: 40px;
		}
	}
	.header__cima{
		margin-bottom: 10px;
		text-align: center;

		.titulo__video{
			margin-bottom: 5px;
		}
	}
	.cursos__opcoes{
		justify-content: center;
	}
	.curso__home__titulo{
		color: #0a1f3c;
		text-align: center;
		padding: 10px 0px;
	}
	.curso__home__desc{
		display: none;
	}
	.video__conteudo{
		.lazy-embed{
			--width: 1920;
			--height: 1080;
		}
	}
}
@include media-breakpoint-down (xs) {
	.credencial__home__txt{
		display: none;
	}
	.loja{
		.container{
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;

			.titulo__loja{
				margin-bottom: 10px;
			}
		}
	}
}
