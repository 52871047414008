.spab__interna{
	padding: 70px 0px 80px 0px;
	overflow: hidden;
}
.spab__img{
	position: relative;
	max-width: 618px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;

	&:before{
		width: 905px;
		height: 607px;
		content: '';
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 0px;
		background-image: url('../images/louros__spab.png');
		background-repeat: no-repeat;
		background-position: center bottom;
		background-size: 905px 607px;
		z-index: -1;
	}
}
.row__spab{
	margin-bottom: 40px;
}
.segura__spab__btn{
	padding-top: 70px;
}
@include media-breakpoint-only (lg) {
	.spab__img{
		&:before{
			width: 670px;
			background-size: 670px auto;
		}
	}
}
@include media-breakpoint-down (md) {
	.spab__img{
		&:before{
			display: none;
		}
	}
	.introducao__btn{
		margin-bottom: 50px;
	}
}
