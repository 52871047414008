@font-face {
    font-family: 'Trajan Pro 3';
    src: url('TrajanPro3-Regular.eot');
    src: url('TrajanPro3-Regular.eot?#iefix') format('embedded-opentype'),
        url('TrajanPro3-Regular.woff2') format('woff2'),
        url('TrajanPro3-Regular.woff') format('woff'),
        url('TrajanPro3-Regular.ttf') format('truetype'),
        url('TrajanPro3-Regular.svg#TrajanPro3-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'montserratblack';
    src: url('#{$fonts}/montserrat-black/montserrat-black-webfont.eot');
    src: url('#{$fonts}/montserrat-black/montserrat-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.svg#montserratblack') format('svg');
    font-weight: normal;
    font-style: normal;

}
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 8, 2019 */



@font-face {
    font-family: 'montserratbold';
    src: url('montserrat-bold-webfont.eot');
    src: url('montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('montserrat-bold-webfont.woff2') format('woff2'),
         url('montserrat-bold-webfont.woff') format('woff'),
         url('montserrat-bold-webfont.ttf') format('truetype'),
         url('montserrat-bold-webfont.svg#montserratbold') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'montserratbold_italic';
    src: url('#{$fonts}/montserrat-bold-italic/montserrat-bold-italic-webfont.eot');
    src: url('#{$fonts}/montserrat-bold-italic/montserrat-bold-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat-bold-italic/montserrat-bold-italic-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat-bold-italic/montserrat-bold-italic-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat-bold-italic/montserrat-bold-italic-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat-bold-italic/montserrat-bold-italic-webfont.svg#montserratbold_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 14, 2019 */



@font-face {
    font-family: 'montserratextrabold';
    src: url('montserrat-extrabold-webfont.eot');
    src: url('montserrat-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('montserrat-extrabold-webfont.woff2') format('woff2'),
         url('montserrat-extrabold-webfont.woff') format('woff'),
         url('montserrat-extrabold-webfont.ttf') format('truetype'),
         url('montserrat-extrabold-webfont.svg#montserratextrabold') format('svg');
    font-weight: normal;
    font-style: normal;

}
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 14, 2019 */



@font-face {
    font-family: 'montserratmedium';
    src: url('montserrat-medium-webfont.eot');
    src: url('montserrat-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('montserrat-medium-webfont.woff2') format('woff2'),
         url('montserrat-medium-webfont.woff') format('woff'),
         url('montserrat-medium-webfont.ttf') format('truetype'),
         url('montserrat-medium-webfont.svg#montserratmedium') format('svg');
    font-weight: normal;
    font-style: normal;

}
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 8, 2019 */



@font-face {
    font-family: 'montserratregular';
    src: url('montserrat-regular-webfont.eot');
    src: url('montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('montserrat-regular-webfont.woff2') format('woff2'),
         url('montserrat-regular-webfont.woff') format('woff'),
         url('montserrat-regular-webfont.ttf') format('truetype'),
         url('montserrat-regular-webfont.svg#montserratregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 14, 2019 */



@font-face {
    font-family: 'montserratsemibold';
    src: url('montserrat-semibold-webfont.eot');
    src: url('montserrat-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('montserrat-semibold-webfont.woff2') format('woff2'),
         url('montserrat-semibold-webfont.woff') format('woff'),
         url('montserrat-semibold-webfont.ttf') format('truetype'),
         url('montserrat-semibold-webfont.svg#montserratsemibold') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'robotoregular';
    font-display: optional;
    src: url('#{$fonts}/roboto_regular/roboto-regular-webfont.eot');
    src: url('#{$fonts}/roboto_regular/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.woff') format('woff'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
