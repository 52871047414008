.curso__interna__conteudo{
	padding: 22px 0px 0px 0px;
	background-color: #dfe2e6;

	.curso__interna__titulo{
		text-align: left;
		padding: 0px;
		margin-bottom: 10px;
		padding: 0px 15px;
	}
	.curso__interna__descricao{
		text-align: justify;
		margin-bottom: 20px;
		font-family: 'montserratsemibold';
		padding: 0px 15px;
	}
	.curso__interna__botao{
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #0a1f3c;
		color: #fff;
		text-align: center;
		font-family: 'montserratsemibold';
		font-size: 18px;
		line-height: 22px;
	}
}
.eventos__interna{
	padding: 75px 0px 95px 0px;

	.cursos__interna__card{
		padding: 0px;
		border: none;
		background-color: transparent;

		&:focus{
			outline: none;
		}
	}
}
.modal__eventos{
	overflow: hidden;

	.modal-dialog{
		max-width: 1110px;
		width: 90%;
		padding: 0px;
		overflow: hidden;

		.modal-content{
			border: none;
			border-radius: 0px;
			padding: 0px;
		}
	}
	.row{
		padding: 0px;
		max-width: 1110px;
		margin-right: auto;
		margin-left: auto;
		justify-content: space-between;

		.col-lg-5{
			padding: 0px;
		}
		.col-lg-7{
			padding: 0px;
		}
	}
	.modal-body{
		padding: 0px;
	}
	.modal-header{
		padding: 0px;
		border: none;
	}
}
.titulo__modal__evento{
	font-family: 'trajan';
	font-size: 28px;
	line-height: 32px;
	color: #032353;
	text-align: center;
	padding-top: 50px;
	padding-right: 25px;
	padding-left: 25px;
}
.sub__modal__evento{
	font-family: 'trajan';
	font-size: 43px;
	line-height: 47px;
	color: #fff;
	text-align: center;
	padding: 25px 25px 15px 25px;
	background-color: #0f2643;
	position: relative;
	z-index: 10;
	margin-bottom: 20px;

	&:before{
		z-index: -1;
		width: 1110px;
		background-color: #0f2643;
		position: absolute;
		top: 0px;
		left: 0px;
		height: 100%;
		content: '';
	}
}
.modal__img{
	position: relative;
	z-index: 12;
	text-align: right;
}
.modal__fechar{
	position: absolute;
	width: 36px;
	height: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px solid #fff;
	background-color: transparent;
	color: #fff;
	right: 10px;
	top: 10px;
	z-index: 100;
	font-size: 20px;
	line-height: 20px;
	transition: all 0.3s linear;

	&:hover{
		background-color: #fff;
		color: #0f2643;
	}
	&:focus{
		outline: none;
	}
}
.modal__input{
	display: block;
	width: 100%;
	height: auto;
	padding: 10px 14px;
	background-color: #f7f7f7;
	font-family: 'montserratsemibold';
	font-size: 14px;
	line-height: 18px;
	color: #1f1f1f;
	border: none;

	&:focus{
		outline: none;
	}

	&::placeholder{
		color: #1f1f1f;
	}
}
.segura__modal__btn{
	display: flex;
	justify-content: center;
	margin-bottom: 15px;

	.btn__conteudo{
		border: none;
	}
}
